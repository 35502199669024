//
// Schedule Board
//
// .section--scheduleboard {}

.scheduleboard {
	position: relative;
	// overflow: hidden;

	@include breakpoint($min: 800px) {
		margin-top: -20vw;
	}
}

// .scheduleboard__white {
// 	background-color: #fff;
// 	min-height: 256px;
// }

.scheduleboard__gradient {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 72px;
	height: 200px;
	background-image: linear-gradient(0deg, rgba(240,243,245,0.00) 0%, #F0F3F5 93%);
}







// Box
.scheduleboard__box {
	position: relative;
	z-index: 2;
	padding-bottom: 200px;
	overflow: hidden;
	// width: 100vw;

	// position: absolute;
	// left: 5vw;
	// top: 72px;
	// transform: translateY(-100%);

	@include breakpoint($min: 800px) {
		// width: 45vw;
		width: 360px;
		margin-left: 5vw;
		overflow: visible;
		order: 3;
	}

	@include breakpoint(md) {
		width: 390px;
	}

	@include breakpoint(lg) {
		width: 410px;
	}

	@include breakpoint($min: 1600px) {
		margin-left: 7.5vw;
	}
}

.scheduleboard__box-inner {
	position: relative;
	z-index: 4;
	width: 90vw;
	max-width: 360px;
	margin-left: auto;
	margin-right: auto;

	background-image: linear-gradient(0deg, #F9FAFB 0%, #F0F3F5 100%);
	box-shadow: 0 2px 7px 0 rgba(0,0,0,0.05), 0 1px 2px 0 rgba(0,0,0,0.06), 0 1px 5px 0 rgba(0,0,0,0.05), 0 2px 8px 0 rgba(0,0,0,0.05), 0 1px 5px 0 rgba(0,0,0,0.05);
	border-radius: $border-radius;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	padding: 20px;

	@include breakpoint($min: 800px) {
		padding: 24px;
		max-width: none;
		width: 100%;
		// width: 45vw;
		// max-width: 464px;
	}

	// .list--arrow a:hover {
	// 	text-decoration: underline;
	// }
}



.scheduleboard__box-inner {
	.scheduleboard__item {
		display: block;
		border: none;

		p,
		.list {
			margin-bottom: 1vw;
		}
	}
}


.scheduleboard__shadow {
	// outline: 1px solid red;
	position: absolute;
	left: 50%;
	bottom: 22px;
	z-index: 3;
	max-width: none;
	transform: translate(-50%, -50%);
}
