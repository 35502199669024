.main {
	padding-top: 96px;

	@include breakpoint($min: 800px) {
		padding-top: 192px;
	}

	.homepage & {
		padding-top: 0;
	}
}



.main,
.background-gradient {
	background: #fff;
	background: linear-gradient(180deg, #f0f3f5 0%, #fff 20%, #fff 100%);
}



.floatinglogo {
	float: left;
	margin-right: 4vw;
	margin-bottom: 5vw;
	width: 120px;

	@include breakpoint($min: 800px) {
		width: 20vw;
		max-width: 240px;
	}
}



.floating-image {
	margin-bottom: 20px;
	max-width: 300px;

	@include breakpoint(sl) {
		width: 35%;
		max-width: none;
	}
}



.floating-content {

	@include breakpoint(sl) {
		width: 60%;
		margin-left: 5%;
	}
}


.cookieplaceholder {
	.btn {
		background-color: color(cyan600);
		border: 1px solid color(cyan700);
	}
}



.footer__wrapper {
	background: color(coolgray100);
}


.footer__navigation--left,
.footer__navigation--center,
.footer__navigation--right {
	li .list__link {
		color: color(coolgray400);

		&::after {
			display: none;
		}

		&:focus span,
		&:hover span{
			text-decoration: underline;
		}
	}
}
