//*******************************************************************************
// HEADER
//*******************************************************************************

.header {
	position: relative;
	left: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	display: grid;
	grid-template-rows: 63px;
	grid-template-columns: repeat(20, 5vw);
	justify-self: center;
	align-self: center;
	padding: 0;
	z-index: 100;
	border-bottom: 1px solid color(coolgray200);
	background-color: color(bright);
	margin-bottom: 0;

	&::after {
		content: '';
		width: 100%;
		height: 100vh;
		position: absolute;
		top: 0;
		left: 0;
		background-color: color(white);
		opacity: 0;
		pointer-events: none;
		transition: $transition;
		z-index: 50;
	}

	&.menu--open {

		&::after {
			opacity: 1;
		}
	}

	@include breakpoint($min: 800px) {
		position: sticky;
	}

	@include breakpoint($min: 1600px) {
		grid-template-rows: 79px;
	}
}


.header__logo {
	display: block;
	grid-row: 1;
	grid-column: 2 / span 2;
	margin-top: 20px;
	// margin-bottom: 2vw;
	// margin-left: auto;
	// margin-right: auto;
	z-index: 200;
	height: auto;
	transition: $transition;

	@include breakpoint($min: 1000px) {
		margin-top: 1vw;
	}

	a {
		display: inline-block;
	}


	img {
		max-width: 48px;
		height: auto;

		@include breakpoint($min: 1000px) {
			max-width: 80px;
			max-height: 128px;
		}
	}
}



.header__logo--right {
	// outline: 1px solid red;
	display: flex;
	align-items: center;
	justify-content: center;
	grid-column: 5 / span 12;
	margin: 0;

	@include breakpoint(mid) {
		grid-column: 14 / span 6;
		justify-content: flex-end;
	}

	.header__logo-wrapper {
		&:first-child {
			margin-right: 20px;
			margin-right: clamp(10px, 5vw, 80px);


			@include breakpoint(mid) {
				margin-right: 30px;
			}
			img {
				max-width: 180px;

				@include breakpoint(mid) {
					max-width: 250px;
				}
			}
		}
	}

	img {
		width: 100%;
		max-width: 100px;
		max-height: 50px;

		@include breakpoint($min: 800px) {
			max-width: 135px;
			justify-items: end;
		}
	}
}

.header__logo--menu {
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-top: 60px;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 50%;
		width: 100vw;
		height: 120%;
		transform: translateY(-50%);
		background-color: color(coolgray200);
	}

	.header__logo-wrapper--mobile {
		&:first-child {
			max-height: 80px;
			transform: translateY(7.5%);
		}

		&:not(:first-child):not(:last-child) {
			// max-width: 200px;
			width: 35vw;
			min-width: 100px;
		}


		&:last-child {
			max-width: 80px;
			width: 15vw;
			min-width: 40px;
			max-height: 80px;
		}

		img {
			max-width: inherit;
			max-height: inherit;
			width: inherit;
			height: inherit;
			min-height: 0;
			margin: auto;
		}
	}
}



//
// Navi
//
.navi {
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	flex-direction: column;
	padding-left: 10vw;
	margin-top: 50px;
	margin-bottom: 0;

	@include breakpoint($min: 800px) {
		// outline: 1px solid red;
		justify-content: flex-start;
		align-items: center;
		flex-direction: row;
		padding-left: 0;
		margin-top: 0;
		max-width: 660px;
	}
}

.navi .list__item {
	@include headline2;
	@include font-family(regular);
	margin-bottom: 1em !important;
	// background-color: #eee;

	svg {
		display: none;
	}

	@include breakpoint($min: 800px) {
		min-width: 80px;
		max-width: 10vw;
		@include bodytextsize;
		@include font-family(regular);
		margin-bottom: 0 !important;
		margin: 0 2.5vw;

		svg {
			display: block;
		}
	}
}

.navi .list__link {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 40px;
	color: color(dark);
	border-bottom: 2px solid transparent;
	transition: all .2s ease;

	@include breakpoint($min: 800px) {
		justify-content: center;
		border-width: 3px;

		&:focus,
		&:hover {
			text-decoration: none;
			border-bottom-color: color(cyan600);

			@include breakpoint($min: 800px) {
				border-width: 3px;
			}
		}
	}


}


// Live Button
.header .btn--navi-livestream,
.navi .list__item--live {
	display: none;
}

.is--live .navi .list__item--live {
	@include breakpoint($min: 800px) {
		display: block;
		min-width: 120px;
	}
}

.is--live .btn--navi-livestream {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 120px;
	height: 40px;
	border: none;
	color: color(white);
	background-color: color(cyan600);
	border: 1px solid color(cyan700);
	border-radius: $border-radius;
	font-size: 12px;
	line-height: 1;
	white-space: nowrap;

	&:focus,
	&:hover {
		text-decoration: none;
		background-color: color(cyan700);
	}

	&:active {
		box-shadow: inset 0 4px 5px 0 rgba(0,0,0,0.25);
	}

	@include breakpoint($min: 800px) {
		font-size: 16px;
		padding: 0 16px;
	}

	.livestream__reddot {
		width: 8px;
		height: 8px;
		margin-right: 8px;
	}
}


.is--live .btn--navi-special {
	position: absolute;
	top: 75px;
	left: 25vw;
	min-width: auto;
	min-height: auto;
	width: 80px;
	height: 24px;
	padding: 0;

	@include breakpoint($min: 800px) {
		display: none;
	}
}


.header__navigation {
	grid-row: 1;
	grid-column: 18 / span 2;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	@include breakpoint(mid) {
		grid-column: 3 / span 11;
		justify-content: center;
	}
}


.showmenu__toggle {
	// outline: 1px solid red;
	border: none;
	background-color: transparent;
	position: relative;
	width: 40px;
	height: 40px;
	padding: 0;
	z-index: 200;

	@include breakpoint(mid) {
		display: none;
	}

	.showmenu__icon {
		width: 100%;
		height: 100%;
		display: block;
		position: relative;
		cursor: pointer;
		background-color: transparent;

		&::before {
			content: '';
			width: 35px;
			height: 4px;
			border-radius: 3px;
			background-color: color(black);
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-40%, -160%) scale(0.8);
			transition: $transition;
			transform-origin: center;
		}
		&::after {
			content: '';
			width: 35px;
			height: 4px;
			border-radius: 3px;
			background-color: color(black);
			display: block;
			position: absolute;
			bottom: 50%;
			left: 50%;
			transform: translate(-40%, 160%) scale(0.8);
			transition: $transition;
			transform-origin: center;
		}

		&.animate {
			&::before {
				animation: animateBurgerTopReverse .4s ease-in-out;
				animation-fill-mode: forwards;
			}

			&::after {
				animation: animateBurgerBottomReverse .4s ease-in-out;
				animation-fill-mode: forwards;
			}
		}
	}
}

.showmenu__toggle[aria-expanded="true"] {
	.showmenu__icon {
		&::before {
			// transform: translate(-40%, -45%) scale(0.8) rotate(45deg);
			animation: animateBurgerTop .4s ease-in-out;
			animation-fill-mode: forwards;
		}
		&::after {
			// transform: translate(-40%, 45%) scale(0.8) rotate(-45deg);
			animation: animateBurgerBottom .4s ease-in-out;
			animation-fill-mode: forwards;
		}
	}
}

.menu {
	position: absolute;
	opacity: 0;
	pointer-events: none;
	display: block;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	z-index: 150;

	&.animate {
		transition: $transition;
	}

	@include breakpoint(mid) {
		opacity: 1;
		pointer-events: all;
		position: relative;
		width: auto;
		height: auto;
		padding-top: 0;
		display: flex;
		flex-direction: column-reverse;
	}
}


body.menu--open {
	overflow: hidden;

	.header {
		&::after {
			opacity: 1;
		}
	}

	.header__logo {
		opacity: 0;
		pointer-events: none;
	}

	.menu {
		opacity: 1;
		pointer-events: all;
		overflow: scroll;
	}
}

@keyframes animateBurgerTop {
	50% {
		transform: translate(-40%, -50%) scale(0.8);
	}

	100% {
		transform: translate(-40%, -45%) rotate(45deg) scale(0.8);
	}
}

@keyframes animateBurgerBottom {
	50% {
		transform: translate(-40%, 50%) scale(0.8);
	}

	100% {
		transform: translate(-40%, 45%) rotate(-45deg) scale(0.8);
	}
}

@keyframes animateBurgerTopReverse {
	0% {
		transform: translate(-40%, -45%) rotate(45deg) scale(0.8);
	}

	50% {
		transform: translate(-40%, -50%) scale(0.8) rotate(0);
	}

	100% {
		transform: translate(-40%, -160%) scale(0.8);
	}
}

@keyframes animateBurgerBottomReverse {
	0% {
		transform: translate(-40%, 45%) rotate(-45deg) scale(0.8);
	}

	50% {
		transform: translate(-40%, 50%) scale(0.8) rotate(0);
	}

	100% {
		transform: translate(-40%, 160%) scale(0.8);
	}
}
