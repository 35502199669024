.livestream {
	// outline: 1px solid red;

	@include breakpoint($min: 800px) {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		// margin-bottom: 40vw;
	}
}



.livestream__programm-headline {
	@include font-family(headblack);
}



.livestream__content {
	position: relative;
	margin-left: 5vw;
	margin-right: 5vw;

	@include breakpoint($min: 800px) {
		// outline: 1px solid red;
		width: 40vw;
		order: 2;
		margin-top: 0;
		margin-right: 0;
		margin-left: 0;
		z-index: 5;

		.is--live & {
			margin-top: 10vw;
		}
	}

	@include breakpoint($min: 1600px) {
		max-width: 480px;
	}

	.livestream__content-headline {
		@include font-family(headblack);
		position: relative;

		.is--live & {
			margin-left: -4.5vw;

			@include breakpoint($min: 800px) {
				margin-left: 0;
			}
		}
	}
}



.livestream__video {
	display: block;
	position: relative;

	@include breakpoint($min: 800px) {
		// outline: 1px solid blue;
		width: 60vw;
		order: 1;
		margin-left: auto;
		margin-right: auto;
	}

	.image {
		border: 2px solid color(cyan600);
		border-radius: $border-radius;
	}

	.btn {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}




