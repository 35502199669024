.logolist {
	// outline: 1px solid red;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: auto;
	gap: 0px 0px;
	// grid-template-areas: "l1 l2 l3" "l4 l5-long l5-long" "l6 l7-long l7-long" "l8 l9 .";

	.list__item {
		// outline: 1px solid #ccc;
		max-width: 212px;
		max-height: 190px;
		padding: 20px;

		@include breakpoint($min: 800px) {
			max-width: 240px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 0;
			padding: 0;
		}

		// &:nth-child(1) { grid-area: l1; }
		// &:nth-child(2) { grid-area: l2; }
		// &:nth-child(3) { grid-area: l3; }
		// &:nth-child(4) { grid-area: l4; }
		// &:nth-child(5) { grid-area: l5-long; }
		// &:nth-child(6) { grid-area: l6; }
		// &:nth-child(7) { grid-area: l7-long; }
		// &:nth-child(8) { grid-area: l8; }
		// &:nth-child(9) { grid-area: l9; }
	}

	.list__item--bigger {
		max-width: 426px;

		@include breakpoint($min: 800px) {
			width: 35vw;
			max-width: 560px;
		}
	}

	.logolist__image {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}



// IE 11 FIX
.msie .logolist {
	display: flex;
	flex-wrap: wrap;

	.list__item {
		max-width: 200px;
		margin: 2vw;
	}
}

