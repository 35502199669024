.intro {
	// outline: 1px solid rebeccapurple;

	@include breakpoint($min: 800px) {
		display: grid;
		grid-template-columns: repeat(18, 1fr);
		grid-template-rows: auto auto;
	}

	@include breakpoint($min: 1600px) {
		grid-template-columns: repeat(16, 1fr);
	}
}



// Cloumn Text Content
.intro__content {
	// outline: 1px solid red;
	padding-top: 24vw;
	margin-bottom: 5vw;

	@include breakpoint($min: 800px) {
		grid-row: 1;
		grid-column-start: 1;
		grid-column-end: span 11;
		margin-top: 16vw;
		margin-bottom: 4vw;
		padding-top: 0;
	}
	@include breakpoint($min: 1600px) {
		grid-column-end: span 8;
	}

}


// Column with Media & Blockquote
.intro__media {
	// outline: 1px solid #000;
	padding-bottom: 8vw;

	@include breakpoint($min: 800px) {
		grid-row: 1;
		grid-column-start: 12;
		grid-column-end: span 8;
		margin-top: 12vw;
		margin-bottom: 4vw;
		padding-bottom: 0;
	}

	@include breakpoint($min: 1600px) {
		grid-column-start: 10;
	}

	.image {
		@include breakpoint($min: 800px) {
			margin-left: 5vw;
			margin-right: 5vw;
		}
	}
}


.intro__blockquote {
	position: relative;
	background-color: color(bright);
	box-shadow: 0 3px 7px 0 rgba(19,24,33,0.05), 0 0 5px 0 rgba(19,24,33,0.05), 0 1px 2px 0 rgba(19,24,33,0.15), 0 3px 8px 0 rgba(0,0,0,0.05), 0 0 2px 0 rgba(19,24,33,0.05);
	border-radius: $border-radius;
	padding: 2vw;
	margin: -10vw 5vw 0 5vw;

	@include breakpoint($min: 800px) {
		margin-top: -5vw;
		margin-right: 0;
		margin-left: 10vw;
		padding: 1vw;
	}

	@include breakpoint($min: 1600px) {
		margin-left: 9vw;
		margin-right: -1vw;
	}

	.blockquote__footer {
		margin: 0;
	}
}
