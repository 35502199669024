.themewall {
	// outline: 1px solid rebeccapurple;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;


	@include breakpoint($min: 800px) {
		display: grid;
		grid-template-columns: repeat(18, 1fr);
		grid-template-rows: 1fr 10vw 10vw 6.625vw 10vw 10vw 6.625vw 20vw;
	}
	@include breakpoint($min: 1600px) {
		grid-template-columns: repeat(16, 1fr);
	}


	.themewall__intro {
		// outline: 1px solid red;
		margin: 0 5vw 5vw 5vw;

		@include breakpoint($min: 800px) {
			grid-row: 1 / span 3;
			grid-column-start: 1;
			grid-column-end: span 8;
			margin: 0;
		}
		@include breakpoint($min: 1600px) {
			grid-column-end: span 6;
			margin-right: 0;
		}
	}

	.themewall__intro h2 {
		@include font-family(headblack);
	}


	.themewall__poster {


		height: 53vw;
		width: 50%;
		width: calc(50% - 5vw);

		@include breakpoint($min: 800px) {
			grid-column-end: span 4;
			width: auto;
			height: auto;
		}
	}

	.themewall__poster--1 {
		@include breakpoint($min: 800px) {
			grid-row: 2 / span 3;
			grid-column-start: 15;
		}
		@include breakpoint($min: 1600px) {
			grid-column-start: 13;
		}
	}

	.themewall__poster--2 {
		margin-top: 10vw;

		@include breakpoint($min: 800px) {
			grid-row: 3 / span 3;
			grid-column-start: 10;
			margin-top: 0;
		}
		@include breakpoint($min: 1600px) {
			grid-column-start: 8;
		}
	}

	.themewall__poster--3 {
		@include breakpoint($min: 800px) {
			grid-row: 5 / span 3;
			grid-column-start: 5;
		}
		@include breakpoint($min: 1600px) {
			grid-column-start: 3;
		}
	}

	.themewall__poster--4 {
		margin-top: 10vw;

		@include breakpoint($min: 800px) {
			grid-row: 7 / span 3;
			grid-column-start: 12;
			margin-top: 0;
		}
		@include breakpoint($min: 1600px) {
			grid-column-start: 10;
		}
	}


	// Poster Link
	.themewall__link {
		position: relative;
		display: block;
		width: 100%;
	}

	.themewall__image {
		position: absolute;
		top: 0;
		left: 0;
		border-radius: $border-radius;
		transition: opacity .2s ease-in-out;

		&:first-child {
			box-shadow: 0 5px 20px 0 rgba(0,0,0,0.10), 0 3px 7px 0 rgba(19,24,33,0.05), 0 0 5px 0 rgba(19,24,33,0.05), 0 1px 2px 0 rgba(19,24,33,0.15), 0 3px 8px 0 rgba(0,0,0,0.05), 0 0 2px 0 rgba(19,24,33,0.05);
		}
	}

	.themewall__link:hover {
		.themewall__image {
			opacity: 0;
		}

		.themewall__image.hover {
			opacity: 1;
		}
	}


}

