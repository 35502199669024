//*******************************************************************************
// MENU
//*******************************************************************************

.menu {
	// grid-row: 2;
	// grid-column: 2/20;
	background-color: #fff;
	z-index: 100;
	// box-shadow: 0px 4px 4px color(grey);

	@include breakpoint(md) {
		// grid-row: 1;
		// grid-column: 7 / span 13;
		// margin-top: 4vw;
		padding: 0;
		box-shadow: none;
		z-index: 10;
	}

	@include breakpoint(lg) {
		// grid-column: 9 / span 11;
	}

	&.is-closed {
		display: none;

		@include breakpoint(md) {
			display: flex;
		}
	}
}



//
// Main Menu List
//
.menu__list {
	margin: 0;
	padding: 0;
	width: 100%;

	@include breakpoint(md) {
		display: flex;
		justify-content: space-around;
		width: auto;
		min-width: 300px;
		padding-right: 0;
	}
}



//
// Menu List Item LI Element
//
.menu__listitem {
	padding: 0;
	margin: 0;

	&::before {
		display: none;
	}

	@include breakpoint(md) {
		// margin: 0 0 0 4vw;
		position: relative;
		margin-left: 2vw;
		margin-right: 2vw;
	}

	&.hidden-on-desktop {
		@include breakpoint(md) {
			display: none;
		}
	}
}



//
// Main Menu Link Item
//
.menu__listitemlink {
	position: relative;
	display: block;
	color: color(dark);
	z-index: 2;
	padding: 8px 0;

	@include breakpoint(md) {
		margin-bottom: 0;
	}

	.text-hidden {
		display: block;
		visibility: hidden;
		font: 0/0 a;
		color: transparent;
		text-shadow: none;
		background-color: transparent;
		border: 0;
	}

	// Arrow down Icon
	.menu__listitemicon {
		position: absolute;
		display: block;
		top: 13px;
		right: 10px;
		width: 14px;
		height: 8px;
		z-index: 3;

		@include breakpoint(md) {
			position: relative;
			top: auto;
			right: auto;
			display: inline-block;
			margin-left: 7px;
			margin-bottom: 2px;
		}
	}
}

.menu__listitemlink--hasdropdown {
	padding-right: 30px;

	@include breakpoint(md) {
		padding-right: 0;
	}
}



//
// Sub-Menu List
//
.menu__sublist {

	@include breakpoint(mid) {
		margin-top: -5px;
		margin-bottom: 16px;
		position: absolute;
		top: 50px;
		background-color: color(bright);
		display: block;
		margin-bottom: 0;
		box-shadow: 0 8px 20px 0 rgba(0,0,0,0.25);
	}

	@include breakpoint(md) {
		left: -20px;
		right: -20px;
	}

	.menu__listitemlink {
		font-size: 16px;
	}
}



//
// Small Overlay Menu under Menu Item
//
.menu__sublist--small {

	padding-left: 1em;

	@include breakpoint(mid) {
		left: auto;
		padding: 16px;
	}

	.menu__listitemlink {
		margin-bottom: 8px;
	}



	&::before {
		content: "";
		display: block;
		width: 41px;
		height: 20px;
		position: absolute;
		top: -14px;
		left: 50%;
		margin-left: -20px;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCA0MSAyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48ZmlsdGVyIHg9Ii0uNSUiIHk9Ii0xLjMlIiB3aWR0aD0iMTAxLjElIiBoZWlnaHQ9IjEwMy42JSIgZmlsdGVyVW5pdHM9Im9iamVjdEJvdW5kaW5nQm94IiBpZD0iYSI+PGZlT2Zmc2V0IGR5PSIyIiBpbj0iU291cmNlQWxwaGEiIHJlc3VsdD0ic2hhZG93T2Zmc2V0T3V0ZXIxIi8+PGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMiIgaW49InNoYWRvd09mZnNldE91dGVyMSIgcmVzdWx0PSJzaGFkb3dCbHVyT3V0ZXIxIi8+PGZlQ29sb3JNYXRyaXggdmFsdWVzPSIwIDAgMCAwIDAuNTEzNzI1NDkgMCAwIDAgMCAwLjU3MjU0OTAyIDAgMCAwIDAgMC42MDc4NDMxMzcgMCAwIDAgMSAwIiBpbj0ic2hhZG93Qmx1ck91dGVyMSIgcmVzdWx0PSJzaGFkb3dNYXRyaXhPdXRlcjEiLz48ZmVNZXJnZT48ZmVNZXJnZU5vZGUgaW49InNoYWRvd01hdHJpeE91dGVyMSIvPjxmZU1lcmdlTm9kZSBpbj0iU291cmNlR3JhcGhpYyIvPjwvZmVNZXJnZT48L2ZpbHRlcj48L2RlZnM+PHBhdGggZD0iTTg1MC41IDBMODY3IDEyaC0zM3oiIGZpbHRlcj0idXJsKCNhKSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTgzMCAyKSIgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
	}

	.menu__listitem {
		@include font-family(regular);
		margin-left: 0;
		margin-bottom: 4px;
		position: relative;

		&:last-child a {
			margin-bottom: 0;
		}
	}

	.menu__listitemlink {
		display: block;
		padding: 10px;
		height: auto;
		margin-bottom: 0;

		span {
			padding: 8px 0;
			height: auto;
		}
	}
}



//
// Toogle open / close States
//
.menu__listitem.is-open {
	.menu__sublist--big.hidden-desktop {
		display: flex !important;
		align-content: center;
		justify-content: space-between;
	}

	.menu__sublist--small.hidden-desktop {
		display: block !important;
		top: 60px;
		left: 50%;
		width: 90%;
		width: calc(100% - 40px);
		max-width: 400px;
		transform: translate(-50%, 0);
		z-index: 300;

		@include breakpoint(md) {
			width: 500px;
			top: 50px;
		}
	}

}
