body {
	&.no-scroll {
		overflow: hidden;
	}
}

.is-hidden {
	display: none !important;
}

.msie {
	.karte {
		svg {
			@include breakpoint(mid) {
				height: 30.75vw !important;
				width: 100% !important
			}
		}
	}
}

.karte {
	overflow-x: auto;

	svg {
		min-width: 1600px;

		@include breakpoint(lg) {
			min-width: unset;
		}
	}

	[id^="map-"]:not([id^="map-position"]) {
		&:not([id$="schaffhausen"]):not([id$="koblenz"]) {
			cursor: pointer;

			&:hover {
				fill: color(cyan600);
			}
		}

		&[id$="basel"],
		&[id$="lauchringen-west"],
		&[id$="griessen"] {
			&:hover {
				fill: #333 !important;
			}
		}
	}

	[id^="map-bruecke"],
	[id$="tunnel"],
	[id^="map-umrichterwerk"],
	[id^="map-bahnuebergang"] {
		&:hover {
			.map-icons {
				stroke: #333;
			}

			path {
				fill: #333 !important;
			}

			line {
				stroke: #333;
			}
		}
	}

	@keyframes pulse {
		from {
			stroke-width: 8px;
			stroke-opacity: 1;

		}
		to {
			stroke-width: 0;
			stroke-opacity: 0;
		}
	}
}

.karte--animate {
	.pulse {
		stroke: #0087B9;
		stroke-width: 2px;
		stroke-opacity: 0;
		fill: white;
		fill-opacity: 0;
		animation-name: pulse;
		animation-duration: 2s;
		animation-iteration-count: 3;
	}

	#map-basel .pulse,
	#map-koblenz .pulse,
	#map-lauchringen-west .pulse,
	#map-griessen .pulse {
		stroke: #333;
	}
}

.tooltips {
	position: absolute;
	top: 0;
	left: 0;

	.tooltip {
		width: 12.5vw;
		max-width: 300px;
		min-width: 250px;
		background-color: color(white);
		box-shadow: 0 0 25px 0 color(coolgray500);
		border-radius: $border-radius;
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(-50%, -105%);
		z-index: 10;

		&::before {
			content: '';
			position: absolute;
			bottom: -20px;
			left: 50%;
			width: 0;
			height: 0;
			border-style: solid;
			-webkit-transform: translate(-50%, -2px);
			transform: translate(-50%, -2px);
			border-width: 20px 15px 0 15px;
			border-color: #fff transparent transparent transparent;
			pointer-events: none;
		}

		.image-wrapper {
			position: relative;

			&:hover {
				&::before {
					opacity: .4;
				}
			}

			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 1;
				pointer-events: none;
				background-color: black;
				opacity: .2;
				transition: $transition;
				border-radius: $border-radius;
			}

			img {
				width: 100%;
				height: auto;
				margin-bottom: 7.5px;
				border-top-left-radius: $border-radius;
				border-top-right-radius: $border-radius;
				cursor: pointer;
			}

			&::after {
				content: '';
				@include background-svg('<svg aria-hidden="true" width="81" height="80"	viewBox="0 0 81 80"	xmlns="http://www.w3.org/2000/svg">	<g fill-rule="nonzero" fill="#fff">	<path d="M40.216 4c19.882 0 36 16.118 36 36s-16.118 36-36 36-36-16.118-36-36c.03-19.87 16.13-35.97 36-36m0-4c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40a40 40 0 00-40-40z"></path><path d="M53.475 41.14l-19.35 11.15a1.25 1.25 0 01-1.85-1.1V28.84a1.25 1.25 0 011.9-1.1l19.35 11.2a1.25 1.25 0 01-.05 2.2z"></path> </g></svg>');
				display: block;
				background-position: center;
				background-repeat: no-repeat;
				background-size: 100%;
				position: absolute;
				height: 50px;
				width: 50px;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				pointer-events: none;
				z-index: 1;
			}
		}


		&__text {
			padding: .5em;

			h4 {
				line-height: 1.3em;
				margin-bottom: .5em;
			}

			p {
				font-size: 14px;
				line-height: 20px;
				margin-bottom: .75em;

				&:last-child {
					margin-bottom: 0 !important;
				}
			}
		}
	}
}


.overlay {
	background-color: rgba(0, 0, 0, 0.9);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	overflow: auto;
	width: 100%;

	&__content {
		width: 90vw;
		z-index: 100;
		margin: 0 auto;

		@include breakpoint(sm) {
			width: 80vw;
		}

		@include breakpoint(md) {
			width: 50vw;
		}
	}

	&__close {
		position: absolute;
		top: 5vh;
		// right: 2.5vw;
		width: 40px;
		right: calc(5vw - (var(--scrollbar-width) / 2));
		height: 40px;
		z-index: 100;
		cursor: pointer;
		transition: 0.2s;
		background-color: rgba(0, 0, 0, 0.5);
		border-top-right-radius: 5px;

		@include breakpoint(sm) {
			right: calc((10vw - 40px) / 2 + (var(--scrollbar-width) / 1.5));
			position: fixed;
			border-radius: 10px;
			background-color: transparent;
		}

		@include breakpoint(md) {
			right: 3vw;
		}

		&:hover {
			svg {
				g {
					stroke: color(cyan600);
				}
			}
		}

		svg {
			width: auto;
			height: auto;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			g {
				transition: 0.2s;
				stroke: #fff;
			}
		}
	}

	&__prev,
	&__next {
		position: fixed;
		top: 50%;
		transform: translateY(-50%);
		height: 90vh;
		overflow: hidden;
		cursor: pointer;
		display: none;

		@include breakpoint(md) {
			display: block;
		}

		.overlay__prev-wrapper,
		.overlay__next-wrapper {
			position: absolute;
			width: max-content;
			height: max-content;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: 0.2s;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;

			svg {
				height: 1em;
				width: auto;
				transition: 0.2s;
				&:first-child {
					margin-right: 1em;
				}

				&:last-child {
					margin-left: 1em;
				}

				g {
					fill: #fff;
					transition: 0.2s;
				}
			}

			span {
				width: fit-content;
				color: #fff;
				transition: .2s;
			}
		}
	}

	&__prev {
		left: 0;
		width: calc(25vw - var(--scrollbar-width) / 2);

		&:hover {
			svg {
				transform: translateX(-0.5em);
				g {
					fill: color(cyan600);
				}
			}

			span {
				color: color(cyan600);
			}
		}
	}

	&__next {
		right: var(--scrollbar-width);
		width: calc(25vw - var(--scrollbar-width) / 2);

		&:hover {
			svg {
				transform: translateX(0.5em);
				g {
					fill: color(cyan600);
				}
			}

			span {
				color: color(cyan600);
			}
		}
	}

	.station {
		height: 100%;
		margin-bottom: 5vh;
		background-color: color(white);
		border-radius: 10px;
		margin-top: 5vh;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin: 0;
		}

		&__video {
			position: relative;

			video {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}

			.video__inner {
				border-radius: 0;
				border-top-right-radius: 10px;
				border-top-left-radius: 10px;

				.btn {
					background-color: color(cyan600);
					border-color: color(cyan600);
				}
			}
		}

		&__title,
		&__subtitle,
		&__text,
		&__close {
			padding: 0 5vw;
			display: block;
		}

		&__title {
			margin: 0;
			padding-top: 2vw;
			padding-bottom: 2vw;
		}

		&__subtitle {
			margin: 0;
			margin-bottom: -1.5vw;
			padding-top: 2vw;
			@include font-family(regular);
		}

		&__text {

			p {
				margin: 0;
				padding-bottom: 2vw;

				@include breakpoint(md) {
					@include overlaytextsize;
				}

				&:last-child {
					padding-bottom: 10vw;

					@include breakpoint(md) {
						padding-bottom: 5vw;
					}
				}
			}
		}

		&__close {
			padding-bottom: 5vw;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			position: relative;
			display: flex;

			@include breakpoint(md) {
				padding-bottom: 2vw;
				display: block;
			}

			button {
				padding: 0;
				border: none;
				background: transparent;
				color: color(cyan600);
				cursor: pointer;
				@include font-family(sansbold);
				display: none;

				@include breakpoint(md) {
					display: block;
				}

				&:hover {
					color: color(cyan700);
				}
			}

			.station__prev-mobile,
			.station__next-mobile {
				display: flex;
				height: 2em;
				justify-content: center;
				align-items: center;
				bottom: 0;
				flex: 0 0 50%;
				text-align: center;
				cursor: pointer;

				@include breakpoint(md) {
					display: none;
				}

				svg {
					height: 1em;
					width: min-content;
					transition: 0.2s;

					&:first-child {
						margin-right: 0.25em;
					}

					&:last-child {
						margin-left: 0.25em;
					}

					g {
						fill: color(cyan600);
					}
				}

				span {
					width: max-content;
					max-width: 125px;
					min-width: 60px;
					line-height: 1.3;
					color: color(cyan600);

					@include breakpoint(sm) {
						// width: fit-content;
						max-width: none;
					}
				}
			}

			.station__prev-mobile {
				justify-content: flex-start;

				&::before {
					content: "";
					@include background-svg('<svg width="21px" height="33px" viewBox="0 0 21 33" xmlns="http://www.w3.org/2000/svg"><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="chevron-left" fill="#0087B9"><path d="M19.434,4.109 C20.369,3.171 20.369,1.664 19.434,0.723 C18.966,0.253 18.354,0.022 17.741,0.022 C17.137,0.022 16.516,0.253 16.053,0.723 L0.753,16.021 L16.053,31.326 C16.989,32.264 18.503,32.264 19.439,31.326 C20.377,30.388 20.377,28.875 19.439,27.935 L7.525,16.021 L19.434,4.109 L19.434,4.109 Z"></path></g></g></svg>');
					background-size: 60%;
					background-position: center;
					background-repeat: no-repeat;
					height: 60%;
					width: 1em;
					margin-right: 0.5em;
				}
			}

			.station__next-mobile {
				justify-content: flex-end;

				&::after {
					content: "";
					@include background-svg('<svg width="20px" height="33px" viewBox="0 0 20 33" xmlns="http://www.w3.org/2000/svg"><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="chevron-right" fill="#0087B9"><path d="M0.851,27.934 C-0.084,28.872 -0.084,30.382 0.851,31.325 C1.319,31.794 1.931,32.021 2.544,32.021 C3.148,32.021 3.769,31.792 4.232,31.325 L19.535,16.022 L4.232,0.722 C3.296,-0.215 1.785,-0.215 0.846,0.722 C-0.092,1.66 -0.092,3.172 0.846,4.108 L12.76,16.022 L0.851,27.934 L0.851,27.934 Z"></path></g></g></svg>');
					background-size: 60%;
					background-position: center;
					background-repeat: no-repeat;
					height: 60%;
					width: 1em;
					margin-left: 0.5em;
				}
			}
		}
	}
}
